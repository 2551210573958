class AbstractCalculationAlgorithm {
  constructor() {
    this._zusDiff = 0.8629;
    this._maxZs = 0.0775;
    this._minTaxBase = undefined;
    this._pitTax = undefined;
    this._kw = undefined;
    this._x = undefined;
  }

  getTaxationBase(salary) {
    return salary * this._zusDiff - this._x;
  }

  getHealthTax(salary) {
    return Math.round(this._zusDiff * this._maxZs * salary);
  }

  getTax(salary, advancedOptions = {}) {
    const taxationBase = this.getTaxationBase(salary);
    if (taxationBase < this._minTaxBase)
      return 0;
    return Math.round(taxationBase) * this._pitTax - this._kw;
  }

  getPit(salary, advancedOptions = {}) {
    const result = this.getTax(salary, advancedOptions) - this.getHealthTax(salary);
    return result > 0 ? result : 0;
  }

  getIncomeFormSalary(salary, tax, advancedOptions = {}) {
    return this.getPit(salary, advancedOptions) * tax;
  }
}

export class Algorithm2019 extends AbstractCalculationAlgorithm {
  constructor() {
    super();
    this._minTaxBase = 257.58;
    this._pitTax = 0.18;
    this._kw = 46.34;
    this._x = 111.25;
  }
}

export class Algorithm2020 extends AbstractCalculationAlgorithm {
  constructor() {
    super();
    this._minTaxBase = 666.67;
    this._pitTax = 0.17;
    this._kw = 43.76;
    this._x = 250;
  }
}

export class Algorithm2022 extends AbstractCalculationAlgorithm {
  constructor() {
    super();
    this._minTaxBase = 2500;
    this._pitTax = 0.17;
    this._kw = 425;
    this._x = 250;
  }

  getHealthTax(salary) {
    return 0;
  }

  getTax(salary, advancedOptions) {
    let tax;

    if (advancedOptions.under26 || advancedOptions.pensioner) {
      tax = this.getTaxForUnder26AndPensioners(salary);
    } else {
      if (salary > 11878) {
        tax = this.getTaxForOthers(salary);
      } else {
        tax = this.getTaxForOthers(salary) * this.getPitTaxForSalary(salary) - this._kw;
      }
    }

    return Math.round(tax < 0 ? 0 : tax);
  }

  getPitTaxForSalary(salary) {
    return this._pitTax;
  }

  getTaxForOthers(salary) {
    let tax;

    if (salary <= 3187) {
      tax = 0;
    } else if (salary > 3187 && salary <= 5700) {
      tax = this.getTaxationBase(salary);
    } else if (salary > 5700 && salary < 8550) {
      tax = Math.round(this.getTaxationBase(salary) - (salary * 0.0668 - 380.5) / this._pitTax);
    } else if (salary >= 8550 && salary <= 11141) {
      tax = Math.round(this.getTaxationBase(salary) - (salary * -0.0735 + 819.08) / this._pitTax);
    } else if (salary > 11141 && salary <= 11878) {
      tax = this.getTaxationBase(salary);
    } else if (salary > 11878 && salary <= 14805) {
      tax = (Math.round(this.getTaxationBase(salary)) - 10000) * 0.32 + 15300 / 12;
    } else {
      tax = (this._zusDiff * 177660 / 12 + 0.9755 * (salary * 12 - 177660) / 12 - this._x - 10000) * 0.32 + 15300 / 12;
    }

    return Math.round(tax);
  }

  getTaxForUnder26AndPensioners(salary) {
    const yearlyLimit = 115528;
    const salaryWithoutLimit = salary * 12 - yearlyLimit;

    let z = 0;
    if (salaryWithoutLimit / salary >= 0) {
      z = Math.ceil(salaryWithoutLimit / salary);
    }

    let res = 0;
    const yearlyTax = Math.round(this._zusDiff * salaryWithoutLimit - this._x * z);
    if (yearlyTax > 0) {
      res = (yearlyTax * this.getPitTaxForSalary(salary)) / 12;
    }

    return Math.round(res);
  }
}

export class Algorithm2023 extends Algorithm2022 {
  constructor() {
    super();
    this._kw = 300;
  }

  getTax(salary, advancedOptions) {
    let tax;

    if (advancedOptions.under26 || advancedOptions.pensioner) {
      tax = this.getTaxForUnder26AndPensioners(salary);
    } else {
      const pitTaxForSalary = this.getPitTaxForSalary(salary);
      if (salary > 11878) {
        tax = (this.getTaxForOthers(salary) - 10000) * pitTaxForSalary + 900;
      } else {
        tax = this.getTaxForOthers(salary) * pitTaxForSalary - this._kw;
      }
    }

    return Math.round(tax < 0 ? 0 : tax);
  }

  getPitTaxForSalary(salary) {
    return salary > 11878 ? 0.32 : 0.12;
  }

  getTaxForOthers(salary) {
    return Math.round(this.getTaxationBase(salary));
  }
}

export class Algorithm2025 extends AbstractCalculationAlgorithm {
  constructor(props) {
    super(props);
    this._kw = 250;
  }

  getIncomeFormSalary(salary, tax, advancedOptions = {}) {
    return tax * (salary - this._kw);
  }
}

export default class SalaryParser {
  constructor(year) {
    if (year >= 2025) {
      this._algorithm = new Algorithm2025();
    } else if (year === 2023) {
      this._algorithm = new Algorithm2023();
    } else if (year === 2022) {
      this._algorithm = new Algorithm2022();
    } else if (year === 2020) {
      this._algorithm = new Algorithm2020();
    } else {
      this._algorithm = new Algorithm2019();
    }
  }

  parseSalary(salary, tax, advancedOptions) {
    const result = this._algorithm.getIncomeFormSalary(salary, tax, advancedOptions);
    const normalizedResult = this.normalizeResult(result);
    const formattedResult = this.formatResult(normalizedResult);
    return [
      normalizedResult,
      formattedResult,
    ];
  }

  normalizeResult(value) {
    return value >= 0 ? value : 0;
  }

  formatResult(value) {
    return value.toFixed(2).toString().replace(/\./g, ',') + ' zł';
  }
}
